// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

section.section-01-text.mecanique {
    background-image: url(../images/mecanique_section01_bg.jpg);
}
section.section-01-text.alignement {
    background-image: url(../images/alignement_section01_bg.jpg);
}
section.section-01-text {
    padding: 115px 10%;
    background-image: url(../images/accueil_section01_bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    div.txt {
        max-width: 1600px;
    }
    h3 {
        font-size: $font-size-40;
        color: $color-white;
        line-height: 1.3;
        @extend .font-2-normal;
    }
    p {
      font-size: $font-size-26;
      color: $color-white;
      line-height: 1.3;
      @extend .font-1-medium;
    }
}
section.section-03.mecanique, section.section-03.alignement {
    border-bottom: none;
}
section.section-03 {
    background-color: $color-3;
    padding: 50px 10%;
    border-bottom: 2px solid $color-2;
    div.container-fluid.row {
        margin: 0;
        padding: 0;
        & > div {
            width: calc(50% - 10px);
        }
    }
    div.left-side, div.right-side {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-1;
        & > div {
            padding: 30px 20px;
        }
    }
    div.right-side.mecanique {
        background: #d2202f url(../images/mecanique_section02_burst02.jpg) no-repeat;
        background-position: bottom;
        justify-content: end;
        align-items: initial;
    }
    h4 {
        color: $color-white;
        font-size: $font-size-40;
        font-family: $font-family-2;
    }
    h3 {
        color: $color-white;
        @extend .font-2-normal;
        font-size: 3rem;
        span {
            font-size: $font-size-30;
        }
    }
    h3.spec {
        line-height: 0.5;
    }
    ul {
        list-style: none;
        padding-left: 40px;
        li {
            line-height: 1.5;
            padding-bottom: 15px;
            color: $color-white;
            position: relative;
            text-transform: initial;
            @extend .font-1-medium;
            font-size: $font-size-28;
            span {
                color: #d21f2e;
            }
            &::before {
                content: '•';
                font-size: 55px;
                position: absolute;
                left: -40px;
                top: -30px;
            }
        }
    }
    @media screen and (max-width: 1200px){
        div.container-fluid.row {
          flex-direction: column;
            & > div {
                width: 100%;
                margin: 20px 0;
            }
        }
        @media screen and (max-width: $size-slider-breakpoint){
            padding: 30px 10px;
            div.container-fluid.row {
                & > div {
                  flex-direction: column;
                  margin: 10px 0;
                }
            }
        }
    }
}

/*******************************************************************************
* MÉCANIQUE GÉNÉRALE
*******************************************************************************/

section.section-03.mecanique {
    .right-side {
        .txt {
            p.txt {
                    line-height: 1.5;
                    color: #fff;
                    font-weight: 500;
                    font-size: 1.25rem;
            }
            .img-responsive {
                margin: auto;
            }
        }
    }
}

/*******************************************************************************
* ALIGNEMENT AU LASER
*******************************************************************************/

aside.alignement {
    padding-bottom: 15px;
    border-bottom: 3px solid #d2202f;
    background: #17468f;
}

/*******************************************************************************
* RENDEZ-VOUS
*******************************************************************************/

section.section-intro.rdv {
    background-color: $color-3;
    padding: 75px 12% 0 12%;
    h2 {
        font-size: $font-size-44;
        color: $color-white;
        font-weight: bold;
    }
    h2.rdv {
        padding-bottom: 15px;
    }
    p {
        font-weight: normal;
        font-size: $font-size-28;
        color: #fff;
        margin: 0;
        line-height: 1.3;
    }
}

section.section-03.rdv {
    .blocFormulaire {
        overflow: hidden;
        .module-formulaire {
            background: #103265;
            padding: 65px 40px;
            .text {
                text-align: center;
                padding: 0 2% 65px 2%;
                p {
                    font-weight: normal;
                    font-size: $font-size-28;
                    color: #fff;
                    margin: 0;
                    line-height: 1.3;
                    font-style: italic;
                }
            }
            h4 {
                font-size: $font-size-40;
                font-weight: normal;
                text-transform: uppercase;
                color: #d2202f;
                font-family: $font-family-2;
                padding-bottom: 20px;
            }
            .form-group.col-xs-6.col-sm-4 {
                padding-left: 0;
            }
            .col-xs-12.col-sm-6.form-group.list-checkBox {
                padding-left: 0;
                label.container {
                    padding-left: 0;
                }
            }
        }
        button#submit {
            margin-left: 33%;
            margin-top: -35px;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

section.section-intro {
    background-color: $color-3;
    padding: 75px 12% 0 12%;
    h2 {
        font-size: $font-size-44;
        color: $color-white;
        font-weight: bold;
    }
}

section.section-03.coordonnees {
    div.container-fluid.row {
        align-items: center;
    }
    .left-side {
        display: block;
        min-height: 800px;
        h3 {
            color: #d2202f;
            font-size: $font-size-48;
        }
        h4 {
            font-size: $font-size-36;
        }
        .info {
            width: 55%;
            margin: auto;
            h4.last {
                padding-bottom: 35px;
            }
        }
        .jours-heures {
            display: flex;
            h4 {
                padding-bottom: 10px;
            }
            .jour {
                width: 50%;
            }
        }
    }
    .right-side {
        display: block;
        background: none;
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1820px) {

section.section-03.coordonnees .left-side .info {
    width: 60%;
}
}

@media screen and (max-width: 1720px) {
    form label {
        width: 40%;
    }
}

@media screen and (max-width: 1685px) {

section.section-03.coordonnees .left-side .info {
    width: 70%;
}
}

@media screen and (max-width: 1615px) {
   
section.section-03.mecanique  div.container-fluid.row.first div.right-side, section.section-03.mecanique  div.container-fluid.row.last div.left-side,  {
    padding: 0 25px;
}
section.section-03 div.right-side.mecanique h3 {
    text-shadow: 0 0 10px $color-black;
}
form label.title-list {
    width: 100%;
}
form .checkmark {
    right: -10%;
}
form label.control-label.date {
    font-size: 1.3rem;
    margin: 8px 25px 0px 0;
}
section.section-03.rdv .blocFormulaire button#submit {
    margin-left: 27%;
    margin-top: -35px;
}
}

@media screen and (max-width: 1460px) {

section.section-03.coordonnees .left-side .info {
    width: 100%;
}
form label.control-label.date {
    font-size: 1.3rem;
    margin: 8px 5px 0px 0;
}
section.section-03.rdv .blocFormulaire button#submit {
    margin-left: 24%;
    margin-top: -35px;
}
form .checkmark {
    right: -20%;
}
}

@media screen and (max-width: $size-md-max) {
    
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a {
    padding-left: 0.5rem;
    padding-right: 0.5rem; 
}
section.section-03.coordonnees .left-side, .module-google-map div#map-canvas {
    min-height: 550px;
    height: 550px !important;
}
form label.control-label.date {
    font-size: 1.2rem;
    margin: 15px 0px 0px 0;
}
section.section-03.rdv .blocFormulaire button#submit {
    margin-left: 20%;
    margin-top: -35px;
}
}

@media screen and (max-width: 1200px) {
   
section.section-03 div.right-side.mecanique {
    background-position: right;
}
section.section-03.mecanique div.container-fluid.row.first div.right-side, section.section-03.mecanique div.container-fluid.row.last div.left-side, section.section-03.alignement  div.container-fluid.row.first div.right-side {
    padding: 25px;
}
section.section-03.coordonnees .right-side .blocMap {
    padding: 30px 0;
}
section.section-03.rdv {
    padding: 50px 2%;
}
section.section-intro.rdv {
    padding: 75px 5% 0 5%;
}
.form-group.col-xs-6.col-sm-5 .flex, .form-group.col-xs-6.col-sm-6 .flex {
    flex-direction: column;
}
form label.control-label.date {
    font-size: 1.6rem;
    margin: 0;
}
}

@media screen and (max-width: $size-sm-max) {
   
.module-header--caption {
    bottom: -18%;
    width: 59%;
}
section.section-03.rdv .blocFormulaire .module-formulaire {
    padding: 50px 20px;
}
form textarea#message {
    margin-left: 0;
}
section.section-03.rdv .blocFormulaire button#submit {
    margin-left: -13px;
    margin-top: -35px;
}
}

@media screen and (max-width: $size-xs-max) {
   
.module-header--caption {
    bottom: -7%;
    width: 95%;
    padding: 25px;
}
section.section-03 div.right-side.mecanique {
    background-position: center;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 213px;
}
section.section-03.coordonnees .left-side .jours-heures .jour {
    width: 65%;
}
.form-group .flex {
    flex-direction: column;
}
form label {
    width: 100%;
}
.form-group.col-xs-6.col-sm-10.bloc1, .form-group.col-xs-6.col-sm-10.bloc2 {
    width: 100%;
}
.form-group.col-xs-6.col-sm-10.bloc1 .form-group.col-xs-6.col-sm-5, .form-group.col-xs-6.col-sm-10.bloc1 .form-group.col-xs-6.col-sm-6, .form-group.col-xs-6.col-sm-10.bloc2 .form-group.col-xs-6.col-sm-5, .form-group.col-xs-6.col-sm-10.bloc2 .form-group.col-xs-6.col-sm-6 {
    padding-left: 0;
    width: 100%;
}
.form-group.col-xs-12.col-sm-8, .col-xs-12.form-group {
    padding-top: 0 !important;
}
.col-xs-12.col-sm-4.form-group.list-checkBox {
    padding-left: 0;
}
.col-xs-12.col-sm-4.form-group.list-checkBox.checkbox2 {
    margin-top: -40px;
}
form .checkmark {
    right: 15px;
}
}

@media screen and (max-width: 360px) {

form label.title-list {
    font-size: 1.8rem;
}
form label {
    font-size: 1.5rem;
}
}