// *************************************************************
// Ce fichier load les fonts et
// définit les classes de font du theme
//

// *************************************************************
// >> Choisir les fonts à loader ici (décommenter celles utilisées ou ajouter des nouvelles fonts)

//***** LOCAL FONTS dans folder /fonts/ *****//
// généré par https://google-webfonts-helper.herokuapp.com/fonts

/* barlow-condensed-300 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/barlow-condensed/barlow-condensed-v5-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-300.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-regular - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/barlow-condensed/barlow-condensed-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-regular.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-600 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/barlow-condensed/barlow-condensed-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-600.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-700 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/barlow-condensed/barlow-condensed-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-700.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-800 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/barlow-condensed/barlow-condensed-v5-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-800.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-900 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/barlow-condensed/barlow-condensed-v5-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-condensed/barlow-condensed-v5-latin-900.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* bebas-neue-regular - latin */
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/bebas-neue/bebas-neue-v2-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/bebas-neue/bebas-neue-v2-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/bebas-neue/bebas-neue-v2-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/bebas-neue/bebas-neue-v2-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/bebas-neue/bebas-neue-v2-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/bebas-neue/bebas-neue-v2-latin-regular.svg#BebasNeue') format('svg'); /* Legacy iOS */
}


//******IMPORTAT POUR L'EVALUATION DE GOOGLE*********/
//font-display: swap;

//***** GOOGLE HOSTED *****//
//***** SANS SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,700i,900');
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i');
// @import url('https://fonts.googleapis.com/css?family=Oswald');
// @import url('https://fonts.googleapis.com/css?family=Fira+Sans');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans');
// @import url('https://fonts.googleapis.com/css?family=Dosis:300,400,700');

//***** SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
// @import url('https://fonts.googleapis.com/css?family=Alegreya');


// >> C'est ici qu'on met les font-family à utiliser
$font-family-1: "Barlow Condensed", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$font-family-2: "Bebas Neue", Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
// $font-family-3: "Roboto Condensed", arial, Helvetica;

// *************************************************************
// >> Les CLASSES de fonts qui sont utilisées dans le theme.
//    Avec les fonts system par défaut

// fira-sans
.font-1-normal { font-family: $font-family-1; font-weight: 400; }
.font-1-light { @extend .font-1-normal; font-weight: 300; }
.font-1-medium { @extend .font-1-normal; font-weight: 500; }
.font-1-bold { @extend .font-1-normal; font-weight: 700; }
// Libre Baskerville
.font-2-normal { font-family: $font-family-2; font-weight: 400; }
.font-2-bold { @extend .font-2-normal;  }



// MAIN FONTS
.font-main {
    @extend .font-1-bold;
    @extend .text-color;
    font-size: $font-size-20;
}


// NAV FONTS
// font pour les items de premier niveau
.font-nav-items {
    @extend .font-2-normal;
    font-size: $font-size-26;
    line-height: 1;
    text-transform: uppercase;
}
// font pour les entete dans les dropdowns
.font-nav-drop-header {
    @extend .font-2-bold;
    font-size: $font-size-24;
}
// font pour les items dans les dropdowns
.font-nav-drop-items {
    @extend .font-1-bold;
    font-size: $font-size-16;
    line-height: 1;
}
.font-nav-phone {
    @extend .font-2-normal;
    font-size: $font-size-30;
}
.font-nav-lang {
  @extend .font-1-normal;
  font-size: $font-size-18;
}
// si le logo est du texte
.font-nav-logo {
    @extend .font-2-normal;
    font-size: $font-size-28;
    //@media screen and (max-width: $size-sm-max){
    //    font-size: $font-size-20;
    //}
    //@media screen and (max-width: $size-xs-max){
    //    font-size: $font-size-14;
    //}
}

// FOOTER FONTS

.font-footer {
    @extend .font-1-normal;
    font-size: $font-size-14;
}
