.module-header {
    position: relative;
    border-bottom: 2px solid $color-2;
    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        background-color: $color-3;
        bottom: -5%;
        right: 0;
        padding: 25px 35px;
        width: 41%;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            text-transform: uppercase;
            color: $color-white;
            font-size: $font-size-48;
            font-weight: normal;
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 3vw;
            font-weight: 300;
            color: $color-1;
            text-shadow: 0 0 10px $color-black;
        }

    }
}