.slideshow-wrap {
    position: relative;
    background-color: black;
    //#layerslider { display: block; }
    //#layerslider-mobile { display: none; }
    //@media screen and (max-width: $size-slider-breakpoint) {
    //    #layerslider { display: none; }
    //    #layerslider-mobile { display: block; }
    //}
    border-bottom: 2px solid $color-2;
    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        @extend .font-2-normal;
        font-size: $font-size-40;
        line-height: 1.2;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-white;
        font-size: $font-size-60;
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        background-color: $color-3;
        bottom: 0;
        right: 0;
        z-index: $z-index-slide-caption;
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption > div {
            padding-right: 1rem; 
        }

    }
}
